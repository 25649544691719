var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"gJ75Mivm5Uax3ZqwsBQaK"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/vender";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn:
    SENTRY_DSN ||
    'https://ab2b3e32783947f6be8d43fcbdae0e42@o468590.ingest.sentry.io/4504520976171008',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.1,
  environment: process.env.PROD,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  integrations: [new Sentry.Replay()],
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  release: process.env.npm_package_version,
  ignoreErrors: [
    /^No error$/,
    /Access is denied/,
    /anonymous function: captureException/,
    /Blocked a frame with origin/,
    /can't redefine non-configurable property "userAgent"/,
    /console is not defined/,
    /Error: AccessDeny/,
    /event is not defined/,
    /partytown/,
  ],
});
